<template>
  <div>
    <!--    {{contact_persons}}-->
    <v-row class="mb-4 justify-center">
      <v-col cols="12" sm="6">
        <v-text-field
            :label="$t('company.person.search.label')"
            :placeholder="$t('company.person.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
        ></v-text-field>
      </v-col>
      <v-col
          class="d-flex ml-md-n5"
          cols="12"
          sm="6"
      >
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="32">mdi-magnify</v-icon>
        </v-btn>

      </v-col>
    </v-row>
    <div :class="{'mmobile': isMobile === true}"
         :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full" id="contact_persons">
        <thead class="">
        <tr class="red">
          <th class="order-2" style="width: 200px; max-width: 200px; min-width: 200px; left: 0;"
              :class="`sticky-col first-col`">{{ $t('company.person.table.first_name') }}
          </th>
          <th style="width: 200px; max-width: 200px; min-width: 160px; left: 160px" class="sticky-col second-col">
            {{ $t('company.person.table.last_name') }}
          </th>
          <template>
            <th style="min-width: 150px; max-width: 150px;">{{ $t('company.person.table.position') }}</th>
            <th style="min-width: 150px; max-width: 150px;">{{ $t('company.person.table.put_through') }}</th>
            <th style="min-width: 250px; max-width: 250px;">{{ $t('company.person.table.phone') }}</th>
            <th style="min-width: 260px; max-width: 300px;">{{ $t('company.person.table.email') }}</th>
            <th style="min-width: 100px; max-width: 100px;">{{ $t('company.person.table.gender.title') }}</th>
            <th style="min-width: 100px; max-width: 100px;">{{ $t('company.person.table.birthday') }}</th>
            <th style="min-width: 140px; max-width: 140px;">{{ $t('company.person.table.identification') }}</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr @click="getItem(item)" v-for="(item, index) in items" :key="index" :class="{'active': item.id === item_id}">
          <td :class="`sticky-col first-col`">{{ item.first_name }}</td>
          <td class="sticky-col second-col">{{ item.last_name }}</td>
          <td>{{ item.position }}</td>
          <td>{{item.put_through === 0 ? $t('company.person.show.put_through.no') : $t('company.person.show.put_through.yes') }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.email }}</td>
          <td>{{ $t(`company.person.table.gender.${item.gender}`) }}</td>
          <td>{{ item.birthday }}</td>
          <td>{{ item.identification }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <v-dialog
        v-model="dialog"
        max-width="800px"
        v-if="dialog"
    >
      <v-card>
        <v-toolbar color="primary" class="mb-4">
          <v-card-title>{{ item.first_name.toUpperCase() }} {{ item.last_name.toUpperCase() }}</v-card-title>
<!--          <v-card-subtitle>{{ $t(`company.person.show.gender.${item.gender}`) }}</v-card-subtitle>-->
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.phone }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.phone') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon :color="item.put_through === 1 ? 'red' : ''">mdi-arrow-decision</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{item.put_through === 0 ? $t('company.person.show.put_through.no') : $t('company.person.show.put_through.yes') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.put_through.title') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.email') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-barcode</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.identification }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.identification') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-cake-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.birthday }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.birthday') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon v-if="item.gender === 'divers' || item.gender === 'indefinite'">
                      mdi-gender-transgender
                    </v-icon>
                    <v-icon color="blue" v-if="item.gender === 'male'">
                      mdi-gender-male
                    </v-icon>
                    <v-icon color="red" v-if="item.gender === 'female'">
                      mdi-gender-female
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(`company.person.show.gender.${item.gender}`) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.gender.title') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
export default {
  props: {
    contact_persons: {
      type: Array,
      default: []
    }
  },
  watch: {
    contact_persons() {
      this.getItems()
    },
    'search.search'() {
      if (this.search.search === '') {
        this.getItems()
      }
    }
  },
  data() {
    return {
      dialog: false,
      isMobile: true,
      height: 0,
      items: [],
      item: {},
      item_id: 1,
      search: {
        search: null,
        search_select: 'last_name'
      },
      search_from: [
        {
          text: this.$t('company.person.search.select.phone'),
          value: 'phone'
        },
        {
          text: this.$t('company.person.search.select.email'),
          value: 'email'
        },
        {
          text: this.$t('company.person.search.select.first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('company.person.search.select.last_name'),
          value: 'last_name'
        },
        // {
        //   text: this.$t('company.person.search.select.identification'),
        //   value: 'identification'
        // },
        {
          text: this.$t('company.person.search.select.position'),
          value: 'position'
        },
      ],
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById('contact_persons').getBoundingClientRect().top + 40)
    },
    getItem(item) {
      this.item = item
      this.dialog = true
    },
    searchItem() {
      if (this.search.search !== null) {
        this.items = this.contact_persons.filter(person => {
          return person[this.search.search_select].toLowerCase().indexOf(this.search.search.toLowerCase()) !== -1
        })
      }
    },
    getItems() {
      this.items = this.contact_persons
    },
    searchClear() {
      this.getItems()
    }
  }
}
</script>
