<template>
  <div style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>

    <v-container fluid>

      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <TableFullCompanies @getId="getId"/>
        </v-col>
        <v-col cols="12" md="6">
          <CompanyShow :company_id="company_id"></CompanyShow>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import TableFullCompanies from "@/components/Tables/TableFullCompanies";
import CompanyShow from "@/components/Company/CompanyShow";
export default {
  components: { TableFullCompanies, CompanyShow },
  data() {
    return {
      preloader: true,
      company_id: null,
    }
  },
  created () {
    setTimeout(this.mxPreloader, 700)
  },
  methods: {
    getId(id) {
      this.company_id = id
    },
    mxPreloader() {
      this.preloader = false
    }
  }
}
</script>
