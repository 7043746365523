<template>
  <div>
    <v-row class="mb-4 justify-center">
      <v-col cols="12" sm="6">
        <v-text-field
            :label="$t('company.search.label')"
            :placeholder="$t('company.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
        ></v-text-field>
      </v-col>
      <v-col
          class="d-flex ml-md-n5"
          cols="12"
          sm="6"
      >
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="32">mdi-magnify</v-icon>
        </v-btn>

      </v-col>
    </v-row>
    <div :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full" id="companies_table">
        <thead class="">
        <tr class="red">
          <th class="order-2" style="width: 280px; max-width: 280px; min-width: 280px; left: 0;"
              :class="`sticky-col first-col`">{{ $t('company.table.company_name') }}
          </th>
          <th style="width: 160px; max-width: 160px; min-width: 160px;">{{
              $t('company.table.company_name_short')
            }}
          </th>
          <th style="min-width: 200px; max-width: 200px;">{{ $t('company.table.company_phone') }}</th>
          <th style="min-width: 200px; max-width: 200px;">{{ $t('company.table.customer.name') }}</th>
<!--          <th style="min-width: 200px; max-width: 200px;">{{ $t('company.table.customer.email') }}</th>-->
<!--          <th style="min-width: 200px; max-width: 200px;">{{ $t('company.table.customer.identification') }}</th>-->
        </tr>
        </thead>
        <tbody>
        <tr @click="getId(item.customer_id)" v-for="(item, index) in items" :key="index"
            :class="{'active': item.id === item_id || item.is_main === 1}">
          <td :class="`sticky-col first-col`">{{ item.company_name }}</td>
          <td>{{ item.company_name_short }}</td>
          <td>{{ item.company_phone }}</td>
          <td>{{ item.customer.first_name }} {{ item.customer.last_name }}</td>
<!--          <td>{{ item.customer.email }}</td>-->
<!--          <td>{{ item.customer.identification }}</td>-->
        </tr>
        </tbody>
      </table>
    </div>
    <v-pagination
        v-if="total_pages > 1"
        v-model="page"
        class="my-4"
        :total-visible="7"
        :length="total_pages"
    ></v-pagination>
  </div>
</template>

<script>

export default {
  data() {
    return {
      page: 1,
      meta: 20,
      total_pages: 0,
      isMobile: true,
      height: 0,
      item: {},
      items: [],
      item_id: 1,
      search: {
        search: null,
        search_select: 'company_name'
      },
      search_from: [
        {
          text: this.$t('company.search.select.company_name'),
          value: 'company_name'
        },
        {
          text: this.$t('company.search.select.company_name_short'),
          value: 'company_name_short'
        },
        // {
        //   text: this.$t('company.search.select.company_phone'),
        //   value: 'company_phone'
        // }
      ],
    }
  },
  watch: {
    page() {
      if (this.search.search === null) {
        this.getItems()
      } else {
        this.searchItem()
      }
    },
    'search.search'() {
      if (this.search.search === '') {
        this.search.search = null
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById('companies_table').getBoundingClientRect().top + 100)
    },
    getId(id) {
      this.item_id = id
      this.$emit('getId', id)
    },
    searchItem() {
      if (this.search.search !== null) {
        this.$store.dispatch('companySearch', this.search).then(() => {
          this.total_pages = 1
          this.items = this.$store.getters.companies.data
        })
      }
    },
    getItems() {
      this.$store.dispatch('companies', this.page).then(() => {
        this.generateItems()
      })
    },
    generateItems() {
      this.items = this.$store.getters.companies.data
      this.meta = this.$store.getters.companies.meta
      this.page = this.$store.getters.companies.meta.current_page

      this.total_pages = Math.ceil(this.$store.getters.companies.meta.total / 25)
    },
    searchClear() {
      this.getItems()
    }
  }
}
</script>
