<template>
  <div>
<!--    {{contact_persons}}-->
    <v-row class="mb-4 justify-center">
      <v-col cols="12" sm="6">
        <v-text-field
            :label="$t('company.address.search.label')"
            :placeholder="$t('company.address.search.placeholder')"
            v-model="search.search"
            solo
            clearable
            hide-details
            @click:clear="searchClear"
            @keypress.enter="searchItem"
            tabindex="1"
        ></v-text-field>
      </v-col>
      <v-col
          class="d-flex ml-md-n5"
          cols="12"
          sm="6"
      >
        <v-select
            v-model="search.search_select"
            :items="search_from"
            label="Search from"
            :append-icon="'mdi-chevron-down'"
            solo
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchItem"
        >
          <v-icon size="32">mdi-magnify</v-icon>
        </v-btn>

      </v-col>
    </v-row>
    <div :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full"  id="company_address">
        <thead class="">
        <tr class="red">
          <th class="order-2" style="width: 180px; max-width: 180px; min-width: 180px; left: 0;" :class="`sticky-col first-col`">{{ $t('company.address.table.country') }}</th>
          <th style="width: 220px; max-width: 220px; min-width: 220px; left: 160px" class="sticky-col second-col">{{ $t('company.address.table.city') }}</th>
          <th style="min-width: 120px; max-width: 120px;">{{ $t('company.address.table.postcode') }}</th>
          <th style="min-width: 300px; max-width: 340px;">{{ $t('company.address.table.street') }}</th>
          <th style="min-width: 120px; max-width: 120px;">{{ $t('company.address.table.is_main') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr @click="getItem(item)" v-for="(item, index) in items" :key="index" :class="{'active': item.id === item_id || item.is_main === 1}">
          <td :class="`sticky-col first-col`">{{ item.country }}</td>
          <td class="sticky-col second-col">{{ item.city }}</td>
          <td>{{ item.postcode }}</td>
          <td>{{ item.street }}</td>
          <td>{{ item.is_main === 0 ? $t('company.address.show.is_main.no') : $t('company.address.show.is_main.yes') }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <v-dialog
        v-model="dialog"
        max-width="800px"
        v-if="dialog"
    >
      <v-card>
        <v-toolbar color="primary" class="mb-6">
          <v-card-title>{{ $t(`company.address.locations`).toUpperCase() }}</v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-earth</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.country }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.address.show.country') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-city</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.postcode }} {{ item.city }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.address.show.postcode_city') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-road</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.street }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.address.show.street') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon :color="item.is_main === 1 ? 'red' : ''">mdi-marker-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.is_main === 0 ? $t('company.address.show.is_main.no') : $t('company.address.show.is_main.yes') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.address.show.is_main.title') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <OpeningHours :opening_hours="item.opening_hours" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import OpeningHours from "@/components/Company/OpeningHours";

export default {
  components: { OpeningHours },
  props: {
    addresses: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialog: false,
      isMobile: true,
      height: 0,
      item: {},
      items: [],
      item_id: 1,
      search: {
        search: null,
        search_select: 'city'
      },
      search_from: [
        {
          text: this.$t('company.address.search.select.country'),
          value: 'country'
        },
        {
          text: this.$t('company.address.search.select.city'),
          value: 'city'
        },
        {
          text: this.$t('company.address.search.select.postcode'),
          value: 'postcode'
        },
        {
          text: this.$t('company.address.search.select.street'),
          value: 'street'
        },
      ],
    }
  },
  watch: {
    addresses() {
      this.getItems()
    },
    'search.search'() {
      if (this.search.search === '') {
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById('company_address').getBoundingClientRect().top + 40)
    },
    getItem(item) {
      this.item = item
      this.dialog = true
    },
    searchItem() {
      if (this.search.search !== null) {
        this.items = this.addresses.filter(item => {
          return item[this.search.search_select].toLowerCase().indexOf(this.search.search.toLowerCase()) !== -1
        })
      }
    },
    getItems() {
      this.items = this.addresses
    },
    searchClear () {
      this.getItems()
    }
  }
}
</script>
